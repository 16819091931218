import $ from "jquery";

// Immediately load jQuery into the global scope (`window`).
//
// This is done early as some of our legacy jQuery plugins are written in a way that
// causes the plugin to try to load itself into jQuery at the time that the plugin is
// imported (FKA a side-effect). This often requires that jQuery be available in the
// global scope at the time.
window.jQuery = $;
window.$ = $;

// This jQuery plugin is imported 'dynamically' (using `import()` instead of
// `import ...`). This forces the plugin to be loaded *after* jQuery is made available
// (above). Vite seems to turn a standard `import ...` into a scenario that causes
// 'magnific-popup' to be loaded in tandem with this script, thus causing a race
// condition. Whereas here, we know the plugin is being loaded *after* jQuery has been
// made available, and *before* the 'DOM content loaded' code runs, which is where
// the plugin is used.
await import("magnific-popup");

import { runOnDOMContentLoaded, configurePrintButtonEvents } from "./common/utils";

runOnDOMContentLoaded(() => {
  /**
   * Add js-enabled class to body to signal that JS is enabled.
   */
  document.body.classList.add("js-enabled");

  /**
   * Add ajax-loading class to body to indicate that an Ajax request is in progress.
   */
  $.ajaxSetup({ traditional: true });
  $("body")
    .ajaxStart(function () {
      document.body.classList.add("ajax-loading");
    })
    .ajaxStop(function () {
      document.body.classList.remove("ajax-loading");
    });

  /**
   * Primary navigation bar dropdown menus
   */

  let dropdownTriggers = document.querySelectorAll(".dropdown-trigger");

  function openDropdownMenu(triggerElement) {
    let dropdownMenu = triggerElement.nextElementSibling;
    dropdownMenu.style.display = "block";
    triggerElement.setAttribute("aria-expanded", "true");
  }

  function closeDropdownMenu(triggerElement) {
    let dropdownMenu = triggerElement.nextElementSibling;
    dropdownMenu.style.display = "none";
    triggerElement.setAttribute("aria-expanded", "false");
  }

  function toggleDropdownMenu(triggerElement) {
    let dropdownMenu = triggerElement.nextElementSibling;

    if (dropdownMenu.style.display === "block") {
      closeDropdownMenu(triggerElement);
    } else {
      openDropdownMenu(triggerElement);
    }
  }

  dropdownTriggers.forEach((trigger) => {
    // Handling 'Enter' and 'Space' key press
    trigger.addEventListener("keydown", function (e) {
      if (e.key === "Enter" || e.keyCode === 13 || e.key === " " || e.keyCode === 32) {
        e.preventDefault(); // Prevent the default action of the 'Space' key (page scrolling)
        toggleDropdownMenu(trigger);
      }
    });

    // Handling click (or touch)
    trigger.addEventListener("click", function () {
      toggleDropdownMenu(trigger);
    });

    let menuParent = trigger.closest(".has-menu"); // Find the closest parent with the 'has-menu' class

    // Handling mouse enter on the parent element with the class 'has-menu'
    menuParent.addEventListener("mouseenter", function () {
      openDropdownMenu(trigger);
    });

    // Handling mouse leave from the parent element with the class 'has-menu'
    menuParent.addEventListener("mouseleave", function () {
      closeDropdownMenu(trigger);
    });
  });

  // Open the dropdown menu when mouse is over the trigger
  function openDropdownMenu(triggerElement) {
    let dropdownMenu = triggerElement.nextElementSibling;
    dropdownMenu.style.display = "block";
    triggerElement.setAttribute("aria-expanded", "true");
  }

  function toggleDropdownMenu(triggerElement) {
    let dropdownMenu = triggerElement.nextElementSibling;

    if (dropdownMenu.style.display === "block") {
      dropdownMenu.style.display = "none";
      triggerElement.setAttribute("aria-expanded", "false");
    } else {
      dropdownMenu.style.display = "block";
      triggerElement.setAttribute("aria-expanded", "true");
    }
  }

  // Handling outside click to close all open dropdowns
  document.addEventListener("click", function (e) {
    if (!e.target.classList.contains("dropdown-trigger")) {
      dropdownTriggers.forEach((trigger) => {
        let dropdownMenu = trigger.nextElementSibling;
        dropdownMenu.style.display = "none";
        trigger.setAttribute("aria-expanded", "false");
      });
    }
  });

  // Preventing event from reaching to document when dropdown or its trigger is clicked
  dropdownTriggers.forEach((trigger) => {
    trigger.addEventListener("click", function (e) {
      e.stopPropagation();
    });

    let dropdownMenu = trigger.nextElementSibling;
    if (dropdownMenu) {
      dropdownMenu.addEventListener("click", function (e) {
        e.stopPropagation();
      });
    }
  });

  /**
   * Tooltips
   */

  document.querySelectorAll("[data-toggle=tooltip]").forEach((tooltipTrigger) => {
    tooltipTrigger.addEventListener("click", function (e) {
      e.preventDefault();
      let tooltip = this.querySelector(".tooltip");
      tooltip.classList.toggle("open");
    });
  });

  /**
   * Disable 'fake' links
   */

  document.querySelectorAll('a[href="#"]').forEach((link) => {
    link.addEventListener("click", function (e) {
      e.preventDefault();
    });
  });

  /**
   * 'Contact us' form.
   */

  $("[data-toggle=lightbox-form]").magnificPopup({
    type: "inline",
    closeMarkup:
      '<button title="%title%" type="button" class="icon-cross-grey mfp-close">Close</button>',
    midClick: true,
    allowHTMLInStatusIndicator: true,
    allowHTMLInTemplate: true,
  });

  /**
   * Brightpath Teacher's Ruler / 'Scale' display
   */

  // Set the height of the segment row based on the descriptor range
  $(".ruler-segment").each(function (i, el) {
    var numberSegment = $(el).find(".ruler-segment-row").length;

    if (i == 0) {
      var height = numberSegment * 50 - 50;
    } else {
      var height = numberSegment * 50;
    }

    $(el)
      .find(".ruler-segment-description")
      .css({
        height: height - 20,
      });
  });

  // add classes for the size of the ruler pip
  $(".ruler-value[data-value$=0]").parent().addClass("large-unit");

  /**
   * Modals
   *
   * Not our Vue.js ones!
   */

  // Close Modal

  /**
   * Close the open modal.
   *
   * @param {Object} options - Options for the function.
   * @param {boolean} options.expectModal - Whether to expect a modal to be open. If
   * `true`, the function will throw an error if no modal is found to close. If `false`,
   * the function will silently return if no modal is found to close.
   *
   * @throws {Error} If no modal is found to close and `options.expectModal` is `true`.
   */
  function closeModal(options = { expectModal: true }) {
    const element = document.querySelector(".modal-container");
    if (element === null && options.expectModal) {
      throw Error("No modal to close.");
    }
    if (element) element.classList.remove("open");
  }

  document.querySelectorAll(".button-close-modal").forEach((button) => {
    button.addEventListener("click", function (event) {
      event.preventDefault();
      closeModal();
    });
  });

  // Close Modal via Esc key
  document.onkeydown = function (event) {
    if (event.key === "Escape") {
      closeModal({ expectModal: false });
    }
  };

  /**
   * Print buttons
   */
  configurePrintButtonEvents();
});
